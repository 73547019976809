.privacy-policy-container {
    font-family: Arial, sans-serif;
    background-color: #f8f8f8;
    padding: 20px;
    border-radius: 8px;
    max-width: 800px;
    margin: 0 auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .privacy-policy-header {
    background-color: rgb(0, 0, 0);
    padding: 20px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    color: white;
    text-align: center;
  }
  
  .privacy-policy-content {
    padding: 20px;
    background-color: white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  
  .privacy-policy-content p {
    font-size: 16px;
    line-height: 1.6;
    color: #333;
  }
  